import AiosPage from "../../omnia/components/general/pages/aios-view";
import FloatingActionBox from "../../omnia/components/elements/floating-action-box";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Link,
    Menu, MenuItem,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import LlmAssistantSelector from "../../omnia/components/modules/ai-studio/model-selector/llm-assistant-selector";
import React, {useState} from "react";
import OverviewTiles from "../../omnia/components/elements/overview-tiles";
import moment from "moment";
import {Link as RouterLink} from "react-router-dom";
import * as PropTypes from "prop-types";
import useOmniaApi from "../../omnia/hooks/use-omnia-api";
import OnIcon from "../../omnia/components/elements/icon";
import SaveButton from "../../omnia/components/elements/save-button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Box} from "@mui/system";


const MAX_LENGTH = 300;


function History() {

    const { t } = useTranslation();
    const { del } = useOmniaApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const sortOptions = [
        {label: t('attributes.modified_at'), value: '-last_message_at'},
        {label: t('attributes.created_at'), value: '-created_at'},
        {label: t('attributes.name'), value: 'name'},
    ];
    const navigate = useNavigate();
    const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
    const menuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSortChange = (option) => {
        setSelectedSort(option);
        setAnchorEl(null);
    };

    const handleNewChat = () => {
        navigate('/beyond-hub/chats');
    }

    return (
        <AiosPage contentMaxWidth="xxl" headerMaxWidth="xxl">
            <FloatingActionBox>
                <Stack direction="row" spacing={2} alignItems="center">
                    <LlmAssistantSelector />
                </Stack>
            </FloatingActionBox>
            <Stack sx={{width: '100%', mt: 4}} direction="column" spacing={2}>
                <Box sx={{position: 'relative'}}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{mb: 2}}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
                            <Typography variant="caption" color="textSecondary">
                                {t('common.sort_by')}
                            </Typography>
                            <Button
                                variant="text"
                                endIcon={<OnIcon iconName="ChevronDown" size="small" />}
                                onClick={handleMenuOpen}
                            >
                                <Typography variant="body1" sx={{fontWeight: 600}} color="textPrimary">
                                    {selectedSort.label}
                                </Typography>
                            </Button>
                            <SaveButton
                                variant="contained"
                                onClick={handleNewChat}
                                label={t('beyond.chats.new_chat')}
                            />
                        </Stack>
                        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
                            {sortOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    onClick={() => handleSortChange(option)}
                                    selected={option.value === selectedSort.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>
                    <Stack sx={{width: '100%', mt: 4}} direction="column" spacing={2}>
                        <OverviewTiles
                            endpoint='core/assistant-chats'
                            query={{assistant__isnull: false, thread_of__isnull: true, ordering: selectedSort?.value}}
                            dense={true}
                            renderTile={(item, index) => {
                                const fullText = item.last_message || "";
                                const truncatedText = fullText.length > MAX_LENGTH ? fullText.slice(0, MAX_LENGTH) + "..." : fullText;

                                return (
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            height: 220,
                                            display: "flex",
                                            flexDirection: "column",
                                            p: 2,
                                        }}
                                    >
                                        <CardHeader
                                            title={
                                                <Link
                                                    component={RouterLink}
                                                    to={`/beyond-hub/chats/${item.id}`}
                                                    variant="h6"
                                                    underline="none"
                                                    sx={{
                                                        color: "inherit",
                                                        ":hover": { textDecoration: "underline" },
                                                    }}
                                                >
                                                    {item.name}
                                                </Link>
                                            }
                                            subheader={moment(item.last_message_at).fromNow()}
                                            action={
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => del("core/assistant-chats", item)}
                                                >
                                                    <OnIcon iconName="Trash01" />
                                                </IconButton>
                                            }
                                            sx={{ p: 0, mb: 1 }}
                                        />

                                        <CardContent
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                overflow: "hidden",
                                                px: 2,
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    width: "100%",
                                                    textAlign: "left",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {truncatedText}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                );
                            }}
                        />

                    </Stack>
                </Box>
            </Stack>
        </AiosPage>
    )
}

export default History;