import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useSettings } from "../../hooks/use-settings";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { useAppMode } from "../../hooks/use-app-mode";
import useSidebarWidth from "../../hooks/use-sidebar-width";
import {useSelector} from "react-redux";

function FloatingActionBox({ placement, children }) {
    const theme = useTheme();
    const settings = useSettings();
    const { isMobile, isFlying } = useIsMobile();
    const appMode = useAppMode();
    const service = useSelector(state => state.service?.user?.context);
    const collapse = useSelector(state => state.service.collapseNavbar);
    const sidebarWidth = useSidebarWidth();
    const layout = appMode.isService ? service?.layout : appMode.layout;
    const hideSidebar = appMode.isService ? collapse : (settings?.navMode === "hidden");

    const offsetTop = isMobile
        ? 50
        : layout === "vertical"
            ? 3
            : isFlying
                ? 5
                : 75;

    const offsetBottom = offsetTop;

    const computedLeft = sidebarWidth + (hideSidebar ? 45 : 5);

    const offsetRight = 5;

    const commonStyle = {
        position: "fixed",
        backdropFilter: "blur(6px)",
        background: alpha(theme.palette.background.paper, 0),
        zIndex: 1200,
        borderRadius: 30,
        px: 2,
        pb: 2,
        pt: 2
    };

    let placementStyle = {};

    switch (placement) {
        case "top-left":
            placementStyle = {
                top: offsetTop,
                left: computedLeft
            };
            break;
        case "top-center":
            placementStyle = {
                top: offsetTop,
                left: `calc((100% + ${computedLeft}px) / 2)`,
                transform: "translateX(-50%)"
            };
            break;
        case "top-right":
            placementStyle = {
                top: offsetTop,
                right: offsetRight
            };
            break;
        case "bottom-left":
            placementStyle = {
                bottom: offsetBottom,
                left: computedLeft
            };
            break;
        case "bottom-center":
            placementStyle = {
                bottom: offsetBottom,
                left: `calc((100% + ${computedLeft}px) / 2)`,
                transform: "translateX(-50%)"
            };
            break;
        case "bottom-right":
            placementStyle = {
                bottom: offsetBottom,
                right: offsetRight
            };
            break;
        default:
            // fallback to top-left if not recognized
            placementStyle = {
                top: offsetTop,
                left: computedLeft
            };
    }

    return (
        <Box sx={{ ...commonStyle, ...placementStyle }}>
            {children}
        </Box>
    );
}

FloatingActionBox.propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf([
        "top-left",
        "top-center",
        "top-right",
        "bottom-left",
        "bottom-center",
        "bottom-right"
    ])
};

export default FloatingActionBox;
