import {
    SET_CHAT_OPEN,
    UPDATE_COLLECTIONS,
    UPDATE_COLLECTION,
    UPDATE_SESSIONS,
    UPDATE_SESSION,
    UPDATE_ACTIONS,
    UPDATE_ACTION,
    UPDATE_LOADING_ACTIONS,
    UPDATE_LOADING_SESSIONS,
    UPDATE_LOADING_COLLECTIONS,
    UPDATE_TEXT_COLLECTIONS,
    UPDATE_TEXT_COLLECTION,
    UPDATE_LOADING_TEXT_COLLECTIONS,
    UPDATE_WORKSPACE,
    UPDATE_WORKSPACES,
    DELETE_WORKSPACE,
    SELECT_WORKSPACE,
    UPDATE_LOADING_WORKSPACES
} from 'src/storage/actions/beyond-actions'
import { produce } from "immer";

const initialState = {
    chatOpen: false,

    collectionsInitialized: false,
    collections: [],
    loadingCollections: false,

    textCollectionsInitialized: false,
    textCollections: [],
    loadingTextCollections: false,

    sessionsInitialized: false,
    sessions: [],
    loadingSessions: false,

    actionsInitialized: false,
    actions: [],
    loadingActions: false,

    workspaces: [],
    workspace: null,
    loadingWorkspaces: false,
    workspacesInitialized: false
};

const beyondHubReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_LOADING_SESSIONS:
            return produce(state, draft => {
                draft.loadingSessions = action.payload;
            })

        case UPDATE_LOADING_COLLECTIONS:
            return produce(state, draft => {
                draft.loadingCollections = action.payload;
            })

        case UPDATE_LOADING_ACTIONS:
            return produce(state, draft => {
                draft.loadingActions = action.payload;
            })

        case UPDATE_ACTIONS:
            return produce(state, draft => {
                draft.actionsInitialized = true;
                draft.actions = action.payload;
            })

        case UPDATE_SESSIONS:
            return produce(state, draft => {
                draft.sessionsInitialized = true;
                draft.sessions = action.payload;
            })

        case UPDATE_COLLECTIONS:
            return produce(state, draft => {
                draft.collectionsInitialized = true;
                draft.collections = action.payload;
            })

        case UPDATE_ACTION:
            return produce(state, draft => {
                const index = draft.actions.findIndex(action => action.id === action.payload.id);
                if(index !== -1) {
                    draft.actions[index] = action.payload;
                } else {
                    draft.actions.push(action.payload);
                }
            })

        case UPDATE_COLLECTION:
            return produce(state, draft => {
                const index = draft.collections.findIndex(collection => collection.id === action.payload.id);
                if(index !== -1) {
                    draft.collections[index] = action.payload;
                } else {
                    draft.collections.push(action.payload);
                }
            })

        case UPDATE_TEXT_COLLECTIONS:
            return produce(state, draft => {
                draft.textCollectionsInitialized = true;
                draft.textCollections = action.payload;
            })

        case UPDATE_TEXT_COLLECTION:
            return produce(state, draft => {
                const index = draft.textCollections.findIndex(collection => collection.id === action.payload.id);
                if(index !== -1) {
                    draft.textCollections[index] = action.payload;
                } else {
                    draft.textCollections.push(action.payload);
                }
            })

        case UPDATE_LOADING_TEXT_COLLECTIONS:
            return produce(state, draft => {
                draft.loadingTextCollections = action.payload;
            })

        case UPDATE_SESSION:
            return produce(state, draft => {
                const index = draft.sessions.findIndex(session => session.id === action.payload.id);
                if(index !== -1) {
                    draft.sessions[index] = action.payload;
                } else {
                    draft.sessions.push(action.payload);
                }
            })

        case SET_CHAT_OPEN:
            return produce(state, draft => {
                draft.chatOpen = action.payload;
            })

        case UPDATE_WORKSPACE:
            return produce(state, draft => {
                const index = draft.workspaces.findIndex(workspace => workspace.id === action.payload.id);
                if(index !== -1) {
                    draft.workspaces[index] = action.payload;
                } else {
                    draft.workspaces.push(action.payload);
                }
            })

        case UPDATE_WORKSPACES:
            return produce(state, draft => {
                draft.workspacesInitialized = true;
                draft.workspaces = action.payload;
            })

        case DELETE_WORKSPACE:
            return produce(state, draft => {
                draft.workspaces = draft.workspaces.filter(workspace => workspace.id !== action.payload);
            })

        case SELECT_WORKSPACE:
            return produce(state, draft => {
                draft.workspace = action.payload;
            })

        case UPDATE_LOADING_WORKSPACES:
            return produce(state, draft => {
                draft.loadingWorkspaces = action.payload;
            })

        default: {
            return state;
        }
    }

};

export default beyondHubReducer;
