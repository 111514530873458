import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import SaveButton from "../../omnia/components/elements/save-button";
import React from "react";
import {useFormik} from "formik";
import useOmniaApi from "../../omnia/hooks/use-omnia-api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import AiosFileSelector from "../../omnia/components/elements/aios-file-selector";
import IconSelector from "../../omnia/components/elements/icon-selector";


function WorkspaceDialog ({ open, onClose, onUpdate, workspace = null }) {

    const { put } = useOmniaApi();
    const { t } = useTranslation();

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);

        put('beyond/workspaces', values).then((data) => {
            onUpdate?.(data);
            onClose?.()
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    }

    const formik = useFormik({
        initialValues: workspace ? workspace : {name: '', description: '', icon: 'Star06'},
        onSubmit: handleSubmit,
        enableReinitialize: true,
    })

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle>
                <Typography variant="h4">
                    {formik.values?.id ? t('common.edit') : t('beyond.workspaces.new_workspace')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={2}>
                    <IconSelector
                        name='icon'
                        error={Boolean(formik.touched.icon && formik.errors.icon)}
                        helperText={formik.touched.icon && formik.errors.icon}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.icon}
                    />
                </Box>
                <TextField
                    sx={{mt: 2}}
                    name="name"
                    label={t('attributes.name')}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                />
                <TextField
                    sx={{mt: 2}}
                    name="description"
                    multiline={true}
                    rows={4}
                    label={t('attributes.description')}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    fullWidth
                />
                {/*<Box sx={{width: '100%', mt: 2}}>*/}
                {/*    <AiosFileSelector*/}
                {/*        disabled={false}*/}
                {/*        title={t("beyond.workspaces.add_logo")}*/}
                {/*        subtitle={t("beyond.workspaces.add_logo_info")}*/}
                {/*        icon='Image01'*/}
                {/*        onChange={(event) => {*/}
                {/*            console.log(event)*/}
                {/*        }}*/}
                {/*        variant='dropzone'*/}
                {/*        multiple={false}*/}
                {/*        values={[]}*/}
                {/*    />*/}
                {/*</Box>*/}
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                    <Button variant="outlined" onClick={onClose}>
                        {t('common.cancel')}
                    </Button>
                    <SaveButton formik={formik} />
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

WorkspaceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    workspace: PropTypes.object
};

export default WorkspaceDialog;
