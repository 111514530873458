import React from "react";
import WorkspaceSelector from "./components/elements/workspace-selector";

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    domain: "metk.groon.io",
    protocol: "https",
    socket_protocol: 'wss',
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: {

        beyondHub: {
            title: 'be.yond',
            icon: 'Plus',
            path: '/groon/beyond',
            permissions: ['beyond_hub'],
            items: [
                {
                    title: 'Workspace',
                    icon: 'LayersTwo01',
                    permissions: ["beyond_hub"],
                    // superCustomAction: 'workspaces',
                    component: <WorkspaceSelector/>
                },
                {
                    title: 'Images',
                    items: [
                        {
                            title: 'Sessions',
                            path: '/groon/beyond/hub/sessions',
                            icon: 'LayersTwo01',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                        {
                            title: 'Images',
                            path: '/groon/beyond/hub/images',
                            icon: 'Image05',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                        {
                            title: 'Saved',
                            path: '/groon/beyond/hub/saved',
                            icon: 'Bookmark',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                    ]
                },
                {
                    title: 'Texts',
                    items: [
                        {
                            title: 'Sessions',
                            path: '/groon/beyond/hub/text-sessions',
                            icon: 'LayersTwo01',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                        {
                            title: 'Texts',
                            path: '/groon/beyond/hub/texts',
                            icon: 'File02',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                        {
                            title: 'Saved',
                            path: '/groon/beyond/hub/text-collections',
                            icon: 'Bookmark',
                            permissions: ["beyond_hub"],
                            // tooltip: ''
                        },
                    ]
                },
                {
                    title: 'Setup',
                    items: [
                        {
                            title: 'Academy',
                            path: '/groon/beyond/academy/courses',
                            icon: 'Image01',
                            permissions: ["beyond_academy"],
                            disabled: true,
                            // tooltip: ''
                        },
                        {
                            title: 'beyond.editors.actions',
                            path: '/groon/beyond/hub/editors',
                            icon: 'Settings01',
                            permissions: ["beyond_hub_editor_config"],
                            // tooltip: ''
                        },
                    ]
                }
            ]
        },
    },

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *     }
     */
    services: [
        {
            name: 'be.yond Hub',
            identifier: 'beyond-hub-light',
            register_redirect: '/beyond-hub'
        },
    ],

    /**
     *
     */
    settings: [],

    /**
     * The duration of how long things fade in
     */
    transitionDuration: 500

}
