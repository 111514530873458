import React from "react";
import {Box, Button} from "@mui/material";
import OnIcon from "../../omnia/components/elements/icon";
import {useTranslation} from "react-i18next";

function ActionButtonMenuItem(){

    const { t } = useTranslation();

    const handleClickedButton = () => {
        alert(t('common.coming_soon'));
    }

    return (
        <Box sx={{p: 2}}>
            <Button
                fullWidth
                startIcon={<OnIcon iconName="Announcement01" />}
                variant="text"
                color="default"
                onClick={handleClickedButton}
            >
                {"What's New"}
            </Button>
        </Box>
    )
}

export default ActionButtonMenuItem;