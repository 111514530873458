import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOmniaApi from "../omnia/hooks/use-omnia-api";
import {
    setWorkspaces,
    updateWorkspace,
    selectWorkspace,
    deleteWorkspace,
    setLoadingWorkspaces
} from "../storage/actions/beyond-actions";
import { produce } from "immer";
import moment from "moment";

function useCollections(sortedBy = "created_at") {
    const dispatch = useDispatch();
    const workspaces = useSelector((state) => state.beyondHub.workspaces);
    const currentWorkspace = useSelector((state) => state.beyondHub.workspace);
    const workspacesInitialized = useSelector((state) => state.beyondHub.workspacesInitialized);
    const loadingWorkspaces = useSelector((state) => state.beyondHub.loadingWorkspaces);
    const { get } = useOmniaApi();

    const onUpdate = (updatedWorkspace) => {
        dispatch(updateWorkspace(updatedWorkspace));
    };

    const onDelete = (workspace) => {
        dispatch(deleteWorkspace(workspace));
    }

    const onSelect = (workspace) => {
        dispatch(selectWorkspace(workspace));
    }

    const updateWorkspaces = () => {
        dispatch(setLoadingWorkspaces(true));
        get("beyond/workspaces/")
            .then((response) => {
                dispatch(setWorkspaces(response));
            })
            .finally(() => {
                dispatch(setLoadingWorkspaces(false));
            });
    }

    useEffect(() => {
        if (!workspacesInitialized && !loadingWorkspaces) {
            updateWorkspaces();
        }
    }, [workspacesInitialized, loadingWorkspaces, dispatch, get]);

    const sortedWorkspaces = useMemo(() => {
        return produce(workspaces, (draft) => {
            return draft.sort((a, b) => {
                switch (sortedBy) {
                    case "name":
                        return a.name.localeCompare(b.name);
                    case "created_at":
                    default:
                        return moment(a.created_at).diff(moment(b.created_at));
                }
            });
        });
    }, [workspaces, sortedBy]);

    return {
        workspaces: sortedWorkspaces,
        currentWorkspace,
        updateWorkspaces: updateWorkspaces,
        initialized: workspacesInitialized,
        onSelect,
        onUpdate,
        onDelete
    };
}

export default useCollections;
