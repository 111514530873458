export const SET_CHAT_OPEN = '@beyond-hub/set-chat-open';
export const UPDATE_COLLECTIONS = '@beyond-hub/update-collections';
export const UPDATE_COLLECTION = '@beyond-hub/update-collection';
export const UPDATE_ACTIONS = '@beyond-hub/update-actions';
export const UPDATE_SESSIONS = '@beyond-hub/update-sessions';
export const UPDATE_SESSION = '@beyond-hub/update-session';
export const UPDATE_ACTION = '@beyond-hub/update-action';
export const UPDATE_LOADING_COLLECTIONS = '@beyond-hub/update-loading-collections';
export const UPDATE_LOADING_SESSIONS = '@beyond-hub/update-loading-sessions';
export const UPDATE_LOADING_ACTIONS = '@beyond-hub/update-loading-actions';
export const UPDATE_TEXT_COLLECTIONS = '@beyond-hub/update-text-collections';
export const UPDATE_TEXT_COLLECTION = '@beyond-hub/update-text-collection';
export const UPDATE_LOADING_TEXT_COLLECTIONS = '@beyond-hub/update-loading-text-collections';
export const UPDATE_WORKSPACE = '@beyond-hub/update-workspace';
export const UPDATE_WORKSPACES = '@beyond-hub/update-workspaces';
export const DELETE_WORKSPACE = '@beyond-hub/delete-workspace';
export const SELECT_WORKSPACE = '@beyond-hub/select-workspace';
export const UPDATE_LOADING_WORKSPACES = '@beyond-hub/update-loading-workspaces';

export function setChatOpen(isOpen) {
    return (dispatch) => dispatch({
        type: SET_CHAT_OPEN,
        payload: isOpen
    })
}

export function setLoadingSessions( loading ) {
    return (dispatch) => dispatch({
        type: UPDATE_LOADING_SESSIONS,
        payload: loading
    })
}

export function setLoadingActions( loading ) {
    return (dispatch) => dispatch({
        type: UPDATE_LOADING_ACTIONS,
        payload: loading
    })
}

export function setCollections( collections ) {
    return (dispatch) => dispatch({
        type: UPDATE_COLLECTIONS,
        payload: collections
    })
}

export function updateCollection( collection ) {
    return (dispatch) => dispatch({
        type: UPDATE_COLLECTION,
        payload: collection
    })
}

export function setLoadingCollections( loading ) {
    return (dispatch) => dispatch({
        type: UPDATE_LOADING_COLLECTIONS,
        payload: loading
    })
}

export function setSessions( sessions ) {
    return (dispatch) => dispatch({
        type: UPDATE_SESSIONS,
        payload: sessions
    })
}

export function updateSession( session ) {
    return (dispatch) => dispatch({
        type: UPDATE_SESSION,
        payload: session
    })
}

export function setActions( actions ) {
    return (dispatch) => dispatch({
        type: UPDATE_ACTIONS,
        payload: actions
    })
}

export function updateAction( action ) {
    return (dispatch) => dispatch({
        type: UPDATE_ACTION,
        payload: action
    })
}

export function setTextCollections( collections ) {
    return (dispatch) => dispatch({
        type: UPDATE_TEXT_COLLECTIONS,
        payload: collections
    })
}

export function updateTextCollection( collection ) {
    return (dispatch) => dispatch({
        type: UPDATE_TEXT_COLLECTION,
        payload: collection
    })
}

export function setLoadingTextCollections( loading ) {
    return (dispatch) => dispatch({
        type: UPDATE_LOADING_TEXT_COLLECTIONS,
        payload: loading
    })
}

export function setWorkspaces( workspaces ) {
    return (dispatch) => dispatch({
        type: UPDATE_WORKSPACES,
        payload: workspaces
    })
}

export function updateWorkspace( workspace ) {
    return (dispatch) => dispatch({
        type: UPDATE_WORKSPACE,
        payload: workspace
    })
}

export function deleteWorkspace( workspace ) {
    return (dispatch) => dispatch({
        type: DELETE_WORKSPACE,
        payload: workspace
    })
}

export function selectWorkspace( workspace ) {
    return (dispatch) => dispatch({
        type: SELECT_WORKSPACE,
        payload: workspace
    })
}

export function setLoadingWorkspaces( loading ) {
    return (dispatch) => dispatch({
        type: UPDATE_LOADING_WORKSPACES,
        payload: loading
    })
}