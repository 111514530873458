import {useTheme} from "@mui/system";
import {Stack, Typography} from "@mui/material";
import OnIcon from "../../omnia/components/elements/icon";
import React, {useEffect} from "react";
import useWorkspaces from "../../hooks/use-workspaces";
import usePopover from "../../omnia/hooks/use-popover";
import WorkspacePopover from "./workspace-popover";
import ClickableInput from "../../omnia/components/elements/clickable-input";

function WorkspaceSelector() {

    const theme = useTheme();
    const workspacesPopover = usePopover();
    const { currentWorkspace, workspaces, initialized, onSelect, onUpdate, onDelete } = useWorkspaces();

    useEffect(() => {

        // Select the first workspace if none is selected
        if (initialized && workspaces.length > 0 && !currentWorkspace) {
            onSelect(workspaces[0]);
        }

        // Select the next one if the current one is deleted
        if (initialized && workspaces.length > 0 && !workspaces.find(w => w.id === currentWorkspace?.id)) {
            onSelect(workspaces[0]);
        }
    }, [initialized, workspaces, currentWorkspace, onSelect]);

    const handleSelect = (workspace) => {
        onSelect(workspace);
        workspacesPopover.handleClose();
    }

    const handleDelete = (workspace) => {
        onDelete(workspace);
        workspacesPopover.handleClose();
    }

    return (
        <>
            <ClickableInput sx={{p: 0}}>
                <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                    sx={{
                        px: 2,
                        height: '100%',
                        width: '100%',
                        minHeight: 35,
                        border: 'solid',
                        borderWidth: 1,
                        borderRadius: '10px',
                        borderColor: 'divider',
                    }}
                    ref={workspacesPopover.anchorRef}
                    onClick={workspacesPopover.handleOpen}
                >
                    <Typography variant="overline" color="text.primary">
                        {currentWorkspace?.name || "be.yond Hub"}
                    </Typography>
                    <OnIcon iconName="ChevronDown" size="small" sx={{color: theme.palette.text.primary}} />
                </Stack>
            </ClickableInput>
            <WorkspacePopover
                workspaces={workspaces}
                popover={workspacesPopover}
                onSelect={handleSelect}
                onUpdate={onUpdate}
                onDelete={handleDelete}
                currentWorkspace={currentWorkspace}
            />
        </>
    )

}

export default WorkspaceSelector;