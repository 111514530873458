import React, {lazy, Suspense} from "react";
import {mergeRoutes} from "src/omnia/routes-composer";
import {Layout as OmniaLayout} from "src/omnia/components/layouts/aios/index";
import {Navigate, Outlet} from "react-router";
import LoadingScreen from "./omnia/components/general/loading-screen";
import AuthGuard from "./omnia/components/general/auth/AuthGuard";
import ServiceGuard from "./omnia/components/general/auth/ServiceGuard";
import {ServiceLayout} from "./omnia/components/layouts/service";
import HistoryMenuItem from "./components/elements/history-menu-item";
import ActionButtonMenuItem from "./components/elements/action-button-menu-item";
import TenantSwitchMenuItem from "./components/elements/tenant-switch-menu-item";
import History from "./views/beyond-hub/history";
import WorkspaceSelector from "./components/elements/workspace-selector";

// Beyond Hub Backend
const BackendEditors = lazy(() => import('src/views/beyond-backend/editors'));
const BackendCourses = lazy(() => import('src/views/beyond-backend/courses'));

// Beyond Hub Context
const BeyondChat = lazy(() => import('src/views/beyond-hub/chat'));
const BeyondCourses = lazy(() => import('src/views/beyond-hub/courses'));
const BeyondImages = lazy(() => import('src/views/beyond-hub/images'));
const BeyondProcesses = lazy(() => import('src/views/beyond-hub/processes'));
const BeyondSaved = lazy(() => import('src/views/beyond-hub/saved'));
const BeyondImageSessions = lazy(() => import('src/views/beyond-hub/image-sessions'));
const BeyondImageSession = lazy(() => import('src/views/beyond-hub/image-session'));
const BeyondShared = lazy(() => import('src/views/beyond-hub/shared'));
const BeyondCollection = lazy(() => import('src/views/beyond-hub/collection'));
const BeyondTextSessions = lazy(() => import('src/views/beyond-hub/text-sessions'));
const BeyondTextSession = lazy(() => import('src/views/beyond-hub/text-session'));
const BeyondTextCollections = lazy(() => import('src/views/beyond-hub/text-collections'));
const BeyondTextCollection = lazy(() => import('src/views/beyond-hub/text-collection'));
const BeyondTexts = lazy(() => import('src/views/beyond-hub/texts'));

export const getRoutes = () => {

    return mergeRoutes([
        {
            path: '/groon/beyond',
            element: (
                <AuthGuard>
                    <OmniaLayout>
                        <Suspense fallback={<LoadingScreen/>}>
                            <Outlet/>
                        </Suspense>
                    </OmniaLayout>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/groon/beyond',
                    element: <Navigate to='/groon/beyond/hub/sessions'/>
                },
                {
                    path: '/groon/beyond/hub/sessions',
                    element: <BeyondImageSessions />
                },
                {
                    path: '/groon/beyond/hub/sessions/:sessionId',
                    element: <BeyondImageSession />
                },
                {
                    path: '/groon/beyond/hub/images',
                    element: <BeyondImages />
                },
                {
                    path: '/groon/beyond/hub/saved',
                    element: <BeyondSaved />
                },
                {
                    path: '/groon/beyond/hub/collection/:collectionId',
                    element: <BeyondCollection />
                },
                {
                    path: '/groon/beyond/hub/editors',
                    element: <BackendEditors />
                },
                {
                    path: '/groon/beyond/hub/academy/courses',
                    element: <BackendCourses />
                },
                {
                    path: '/groon/beyond/hub/texts',
                    element: <BeyondTexts/>
                },
                {
                    path: '/groon/beyond/hub/text-sessions',
                    element: <BeyondTextSessions/>
                },
                {
                    path: '/groon/beyond/hub/text-sessions/:sessionId',
                    element: <BeyondTextSession/>
                },
                {
                    path: '/groon/beyond/hub/text-sessions/:sessionId/texts/:textId',
                    element: <BeyondTextSession/>
                },
                {
                    path: '/groon/beyond/hub/text-collections',
                    element: <BeyondTextCollections/>
                },
                {
                    path: '/groon/beyond/hub/text-collections/:collectionId',
                    element: <BeyondTextCollection/>
                },
            ]
        },
        {
            path: '/beyond-hub',
            element: (
                <ServiceGuard serviceIdentifier={['beyond-hub-light']}>
                    <ServiceLayout
                        tenantSwitch={<WorkspaceSelector />}
                        actionButton={<ActionButtonMenuItem />}
                        layout='vertical'
                        sections={[
                            {
                                title: 'Home',
                                icon: 'Home01',
                                path: '/beyond-hub/chats/compose',
                                items: [
                                    {
                                        title: "New Chat",
                                        disabled: false,
                                        path: '/beyond-hub/chats',
                                        icon: 'Plus',
                                    },
                                    {
                                        title: "History",
                                        disabled: false,
                                        path: '/beyond-hub/history',
                                        icon: 'Clock',
                                        // component: <HistoryMenuItem />,
                                    },
                                ]
                            },
                            {
                                title: 'Visual',
                                icon: 'Home01',
                                path: '/beyond-hub/chats/compose',
                                items: [
                                    {
                                        title: "Sessions",
                                        path: '/beyond-hub/sessions',
                                        icon: 'LayersTwo01',
                                        // items: [
                                        //     {
                                        //         title: "Test A",
                                        //         path: "/beyond-hub/sessions/test-a",
                                        //     },
                                        //     {
                                        //         title: "Test B",
                                        //         path: "/beyond-hub/sessions/test-a"
                                        //     }
                                        // ]
                                    },
                                    {
                                        title: "Images",
                                        path: '/beyond-hub/images',
                                        icon: 'Image05',
                                    },
                                    {
                                        title: "Saved",
                                        path: '/beyond-hub/saved',
                                        icon: 'Bookmark',
                                    },
                                    {
                                        title: "Shared",
                                        path: '/beyond-hub/shared',
                                        icon: 'Share01',
                                        disabled: true
                                    }
                                ]
                            },
                            {
                                title: 'Text',
                                icon: 'Home01',
                                path: '/beyond-hub/chats/compose',
                                items: [
                                    {
                                        title: "Sessions",
                                        path: '/beyond-hub/text-sessions',
                                        icon: 'LayersTwo01',
                                    },
                                    {
                                        title: "Texts",
                                        path: '/beyond-hub/texts',
                                        icon: 'File02',
                                    },
                                    {
                                        title: "Saved",
                                        path: '/beyond-hub/text-collections',
                                        icon: 'Bookmark',
                                    },
                                ]
                            },
                            {
                                title: 'Learn',
                                items: [
                                    {
                                        title: "Courses",
                                        path: '/beyond-hub/courses',
                                        icon: 'GraduationHat01',
                                        disabled: true
                                    },
                                    {
                                        title: "Processes",
                                        path: '/beyond-hub/processes',
                                        icon: 'Share07',
                                        disabled: true
                                    },
                                ]
                            }
                        ]}
                        menuItems={[
                            // {
                            //     title: "Assistants",
                            //     disabled: true,
                            //     path: '/beyond-hub/assistants',
                            //     icon: <SettingsIcon fontSize="small"/>,
                            // },

                        ]}
                    >
                        <Suspense fallback={<LoadingScreen/>}>
                            <Outlet/>
                        </Suspense>
                    </ServiceLayout>
                </ServiceGuard>
            ),
            children: [
                {
                    path: '/beyond-hub',
                    element: <Navigate to="/beyond-hub/chats"/>
                },
                {
                    path: '/beyond-hub/chats',
                    element: <BeyondChat/>
                },
                {
                    path: '/beyond-hub/chats/:chatId',
                    element: <BeyondChat/>
                },
                {
                    path: '/beyond-hub/history',
                    element: <History />
                },
                {
                    path: '/beyond-hub/courses',
                    element: <BeyondCourses/>
                },
                {
                    path: '/beyond-hub/images',
                    element: <BeyondImages/>
                },
                {
                    path: '/beyond-hub/processes',
                    element: <BeyondProcesses/>
                },
                {
                    path: '/beyond-hub/saved',
                    element: <BeyondSaved/>
                },
                {
                    path: '/beyond-hub/sessions',
                    element: <BeyondImageSessions/>
                },
                {
                    path: '/beyond-hub/sessions/:sessionId',
                    element: <BeyondImageSession/>
                },
                {
                    path: '/beyond-hub/texts',
                    element: <BeyondTexts/>
                },
                {
                    path: '/beyond-hub/text-sessions',
                    element: <BeyondTextSessions/>
                },
                {
                    path: '/beyond-hub/text-sessions/:sessionId',
                    element: <BeyondTextSession/>
                },
                {
                    path: '/beyond-hub/text-sessions/:sessionId/texts/:textId',
                    element: <BeyondTextSession/>
                },
                {
                    path: '/beyond-hub/text-collections',
                    element: <BeyondTextCollections/>
                },
                {
                    path: '/beyond-hub/text-collections/:collectionId',
                    element: <BeyondTextCollection/>
                },
                {
                    path: '/beyond-hub/shared',
                    element: <BeyondShared/>
                },
                {
                    path: '/beyond-hub/collection/:collectionId',
                    element: <BeyondCollection/>
                },
            ]
        }
    ])
}
