import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, MenuItem, Stack} from "@mui/material";
import PropTypes from "prop-types";
import WorkspaceTitle from "./workspace-title";
import usePopover from "../../omnia/hooks/use-popover";
import {useIsMobile} from "../../omnia/hooks/use-is-mobile";
import OnIcon from "../../omnia/components/elements/icon";
import {useTheme} from "@mui/system";

const WorkspaceMenuItem = (props) => {

    const {
        workspace,
        onSelect,
        onEdit,
        onDelete,
        checked,
        dense = false,
        ...rest
    } = props;

    const popover = usePopover(true);
    const { isMobile } = useIsMobile();
    const theme = useTheme();

    return (
        <>
            <MenuItem
                key={workspace.id}
                onClick={() => onSelect(workspace)}
                onMouseEnter={() => {
                    if(!isMobile) {
                        popover.handleOpen();
                        popover.queueOpen();
                    }
                }}
                onMouseLeave={() => {
                    if(!isMobile){
                        popover.queueClose();
                    }
                }}
                selected={checked}
                ref={popover.anchorRef}
                sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', minHeight: isMobile ? 22 : 'auto', py: dense ? 0.5 : 1, px: 1}}
                {...rest}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{width: '100%'}}
                >
                    <Box
                        sx={{
                            width: 30 + 'px !important',
                            height: 30 + 'px !important',
                            borderRadius: '10px',
                            backgroundColor: theme.palette.neutral[theme.palette.mode === 'light' ? 300 : 800]
                        }}
                    >
                        <Stack sx={{width: '100%', height: '100%'}} justifyContent="center" alignItems="center">
                            {workspace?.icon && <OnIcon iconName={workspace?.icon} size="small" fontSizeAdjust={10} />}
                        </Stack>
                    </Box>
                    <WorkspaceTitle
                        workspace={workspace}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        justifyContent="space-between"
                    />
                </Stack>
            </MenuItem>
        </>
    )
}

WorkspaceMenuItem.propTypes = {
    workspace: PropTypes.object,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    checked: PropTypes.bool,
    dense: PropTypes.bool,
    mode: PropTypes.oneOf(['switch', 'click']),
}

export default WorkspaceMenuItem;