import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import useTabs from "../../hooks/use-tabs";
import {useIsMobile} from "../../hooks/use-is-mobile";
import OnIcon from "./icon";
import {useDropzone} from "react-dropzone";
import {useDialog} from "../../hooks/use-dialog";
import {useTheme} from "@mui/system";
import useOmniaApi from "../../hooks/use-omnia-api";
import PropTypes from "prop-types";
import ClickableInput from "./clickable-input";
import OverviewTiles from "./overview-tiles";
import {imageTypes} from "../../utils/file-type-helpers";


function AiosFileSelector(props){

    const {

        // Design
        variant = "dropzone",
        title = "Upload your stuff",
        subtitle = null,
        icon = "Upload01",

        // Form
        name = 'files',
        values = [],  // Either object or array
        onChange,
        onFilesChange,
        multiple = false,

        disabled = false

    } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const { tab, handleTabChange } = useTabs('upload');
    const [ uploading, setUploading ] = useState(false);
    const [ selected, setSelected ] = useState(values || []);  // List of currently selected ids
    const [ readyFiles, setReadyFiles ] = useState(values || []);  // List of files
    const [ uploadingFiles, setUploadingFiles ] = useState([]);  // List of files
    const { post } = useOmniaApi({fileFields: ['files']});
    const { isMobile } = useIsMobile();
    const dialog = useDialog();

    const handleSelect = () => {
        onChange?.({
            target: {
                name: name,
                value: multiple ? selected : (selected?.[0] || null)
            }
        })
        dialog.handleClose();
    }

    const handleSelectFile = (file) => {
        setSelected(prev => {
            if(multiple){
                const index = prev.findIndex(f => f?.id === file?.id);
                if(index === -1){
                    return [...prev, file];
                } else {
                    return prev.filter(f => f?.id !== file?.id);
                }
            } else {
                return [file];
            }
        })
    }

    const handleUpload = (values) => {

        // If the component is disabled, do nothing
        if(disabled) return;

        // Set the right tab
        handleTabChange(null, 'files');

        // Disable the select button while upload
        setUploading(true);

        // Set the uploading files
        setUploadingFiles(values);

        // Send the files unified to the API
        post('core/files', {files: values}).then(files => {

            // Reset the uploading files
            setSelected(files);
            setUploadingFiles([]);

        }).finally(() => {

            // Release the select button (if multiple)
            setUploading(false);

        })

    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop: handleUpload});

    const handleClick = () => {
        dialog.handleOpen();
    };

    const visualName = selected?.length > 1 ? `${selected?.length} files` : selected?.[0]?.name;

    useEffect(() => {
        let currentFilesStats = [];
        readyFiles?.map(f => {
            currentFilesStats.push({id: f?.id, name: f?.name, isUploading: false});
        });
        uploadingFiles.map(f => {
            // TODO: It depends a bit what we have
            currentFilesStats.push({id: null, name: f?.name, isUploading: true});
        });
        onFilesChange?.(currentFilesStats);
    }, [readyFiles, uploadingFiles]);

    useEffect(() => {
        setReadyFiles(values);
    }, [values]);

    return (
        <>
            {variant === 'dropzone' && (
                <Box
                    sx={{
                        height: 150,
                        width: '100%',
                        alignItems: 'center',
                        border: 1,
                        borderRadius: `${theme?.config?.input_radius || '10'}px`,
                        borderStyle: 'dashed',
                        borderColor: 'divider',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        outline: 'none',
                        p: 2,
                        ...((uploading || disabled) ? {backgroundColor: 'action.disabled'} : {}),
                        ...((isDragActive && !(uploading || disabled)) && {
                            backgroundColor: 'action.active',
                            opacity: 0.5
                        }),
                        '&:hover': (uploading || disabled) ? {} : {
                            backgroundColor: 'action.hover',
                            cursor: 'pointer',
                            opacity: 0.5
                        }
                    }}
                    {...getRootProps({
                        onClick: (event) => {
                            event.stopPropagation();
                            handleClick();
                        }
                    })}
                >
                    <input {...getInputProps()} />
                    <Stack direction="column" alignItems="center" spacing={2}>
                        {icon && <OnIcon iconName={icon} fontSize="large" sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary}} />}
                        <Stack direction="column" alignItems="center" spacing={0.5}>
                            {title && (
                                <Typography disabled={disabled} variant="h6" sx={{ textAlign: 'center' }}>
                                    {title}
                                </Typography>
                            )}
                            {subtitle && (
                                <Typography disabled={disabled} variant="body1" sx={{ textAlign: 'center' }}>
                                    {subtitle}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </Box>
            )}
            {variant === 'text-field' && (
                <ClickableInput disabled={disabled} onClick={disabled ? null : dialog.handleOpen} sx={{width: '100%'}}>
                    {selected?.length > 0 ? (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{p: 0.5, maxWidth: 200}}>
                            <OnIcon iconName="File02" fontSize="small" sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary}} />
                            <Typography
                                variant="body1"
                                textAlign="left"
                                disabled={disabled}
                                noWrap
                                color={disabled ? "textDisabled" : "textPrimary"}
                            >
                                {visualName}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{p: 0.5}}>
                            <OnIcon iconName="File02" fontSize="small" sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary}} />
                            <Typography
                                variant="body1"
                                textAlign="left"
                                color={disabled ? "textDisabled" : "textSecondary"}
                                disabled={disabled}
                            >
                                {t('common.select_file')}
                            </Typography>
                        </Stack>
                    )}
                </ClickableInput>
            )}
            <Dialog
                open={dialog.open}
                onClose={() => {
                    if(!uploading) {
                        dialog.handleClose();
                    }
                }}
                fullWidth
                fullScreen={isMobile}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h4" color="textPrimary">
                        {t('common.select_file')}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{p: 0}}>
                    <Box px={2}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label={t("common.upload_file")} value="upload" />
                            <Tab label={t("common.my_files")} value="files" />
                            <Tab label={t("common.my_media")} value="media" disabled={true}/>
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box p={2} sx={{height: 550}}>

                        {tab === 'upload' && (
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    alignItems: 'center',
                                    border: 1,
                                    borderRadius: `${theme?.config?.input_radius || '10'}px`,
                                    borderStyle: 'dashed',
                                    borderColor: 'divider',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    outline: 'none',
                                    ...((uploading || disabled) ? {backgroundColor: 'action.disabled'} : {}),
                                    ...((isDragActive && !(uploading || disabled)) && {
                                        backgroundColor: 'action.active',
                                        opacity: 0.5
                                    }),
                                    '&:hover': (uploading || disabled) ? {} : {
                                        backgroundColor: 'action.hover',
                                        cursor: 'pointer',
                                        opacity: 0.5
                                    }
                                }}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <Stack direction="column" alignItems="center" spacing={2}>
                                    {icon && <OnIcon iconName={icon} fontSize="large" sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary}} />}
                                    <Stack direction="column" alignItems="center" spacing={0.5}>
                                        {title && (
                                            <Typography disabled={disabled} variant="h6" sx={{ textAlign: 'center' }}>
                                                {title}
                                            </Typography>
                                        )}
                                        {subtitle && (
                                            <Typography disabled={disabled} variant="body1" sx={{ textAlign: 'center' }}>
                                                {subtitle}
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                            </Box>
                        )}

                        {tab === 'files' && (
                            <OverviewTiles
                                // TODO: Include with search later on as with OverviewList
                                dense={true}
                                endpoint='core/files'
                                query={{ordering: '-created_at'}}
                                loadingCardHeight={150}
                                showConstantLoading={uploading}
                                loadingCardAmount={uploading ? uploadingFiles?.length : 5}
                                renderTile={(file) => (
                                    <Card
                                        key={'file-' + file?.id}
                                        variant="outlined"
                                        onClick={() => handleSelectFile(file)}
                                        sx={(theme) => ({
                                            height: 150,
                                            cursor: 'pointer',
                                            position: 'relative', // Ensure proper stacking
                                            overflow: 'hidden', // Prevents image from overflowing
                                            backgroundColor: imageTypes.includes(file?.type)
                                                ? 'transparent'
                                                : 'white',
                                            borderRadius: '8px',
                                            border: selected?.map(f => f?.id).includes(file?.id)
                                                ? 'none'
                                                : '1px solid rgba(0, 0, 0, 0.12)',
                                        })}
                                    >
                                        {/* Show Image as Background */}
                                        {imageTypes.includes(file?.type) ? (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundImage: `url(${file?.view_medium})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    borderRadius: '8px', // Ensures clean edges
                                                    opacity: selected?.map(f => f?.id).includes(file?.id) ? 0.7 : 1, // Slight fade when selected
                                                    transition: 'opacity 0.2s ease-in-out',
                                                }}
                                            />
                                        ) : (
                                            // Show File Name & Type only if it's NOT an image
                                            <Stack
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                    p: 1,
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    borderRadius: '8px',
                                                }}
                                                direction="column"
                                                justifyContent="flex-end"
                                                alignItems="flex-start"
                                            >
                                                <Typography sx={{width: '90%'}} variant="subtitle2" noWrap>
                                                    {file?.name}
                                                </Typography>
                                                <Typography sx={{width: '90%'}} variant="caption" noWrap>
                                                    {file?.type?.toUpperCase()}
                                                </Typography>
                                            </Stack>
                                        )}
                                    </Card>

                                )}
                            />
                        )}

                        {tab === 'media' && (
                            <></>
                        )}

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSelect}
                        variant="contained"
                        color="primary"
                    >
                        {multiple ? t("common.select_files") : t("common.select_file")}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )

}

AiosFileSelector.propTypes = {
    variant: PropTypes.oneOf(['dropzone', 'text-field']),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    onFilesChange: PropTypes.func,
    multiple: PropTypes.bool,
    fileSettings: PropTypes.array,
    disabled: PropTypes.bool
}

export default AiosFileSelector;
