import React, {useState} from "react";
import PropTypes from "prop-types";
import {Fade, IconButton, List, Popover, Stack, Typography} from "@mui/material";
import usePopover from "../../omnia/hooks/use-popover";
import OnIcon from "../../omnia/components/elements/icon";
import ShareButton from "../../omnia/components/elements/sharing/share-button";
import RemoveSharableObject from "../../omnia/components/elements/sharing/remove-sharable-object";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import {useIsMobile} from "../../omnia/hooks/use-is-mobile";

function WorkspaceTitle(props) {

    const {
        workspace,
        variant = 'subtitle1',
        justifyContent = 'flex-start',
        onDelete,
        onEdit,
        ...rest
    } = props;

    const popover = usePopover();
    const [ hovered, setHovered ] = useState(false);
    const { t } = useTranslation();
    const theme = useTheme();

    const handleOpenMore = (event) => {
        event.preventDefault();
        event.stopPropagation();
        popover.handleOpen();
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            sx={{width: '100%'}}
            justifyContent={justifyContent}
            spacing={2}
            {...rest}
        >
            <Typography variant={variant} color="text.primary">
                {workspace?.name}
            </Typography>
            <Fade in={hovered}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {(workspace?.can_edit) && (
                        <IconButton size="small" onClick={() => onEdit(workspace)}>
                            <OnIcon iconName="Edit01" size="small" />
                        </IconButton>
                    )}
                    {workspace?.can_edit && (
                        <IconButton
                            ref={popover.anchorRef}
                            size="small"
                            onClick={handleOpenMore}
                        >
                            <OnIcon iconName="DotsHorizontal" size="small" />
                        </IconButton>
                    )}
                    <Popover
                        anchorEl={popover.anchorRef.current}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                        transformOrigin={{
                            horizontal: 'left',
                            vertical: 'top'
                        }}
                        onClose={popover.handleClose}
                        open={popover.open}
                        slotProps={{
                            paper: {
                                sx: {
                                    ...{borderRadius: theme?.config?.card_radius + "px"},
                                    ...{p: 0},
                                    ...{minWidth: 'auto'},
                                },
                            },
                        }}
                    >
                        <List>
                            <ShareButton
                                entity={workspace}
                                endpoint='beyond/workspaces'
                                tooltipPlacement="right"
                                withIcon={true}
                            />
                            <RemoveSharableObject
                                entity={workspace}
                                endpoint='beyond/workspaces'
                                tooltipPlacement="right"
                                removeTooltip={onDelete ? t("beyond.workspaces.delete_workspace") : t("beyond.workspaces.deleting_not_allowed")}
                                onRemove={onDelete}
                                noRedirect={true}
                                withIcon={true}
                                disabled={onDelete === null}
                            />
                        </List>
                    </Popover>
                </Stack>
            </Fade>
        </Stack>
    )
}

WorkspaceTitle.propTypes = {
    workspace: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    justifyContent: PropTypes.string,
    variant: PropTypes.string,
}

export default WorkspaceTitle;