import React from "react";
import {
    Box,
    List,
    MenuItem, Popover,
    Stack,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {useDialog} from "../../omnia/hooks/use-dialog";
import {useTranslation} from "react-i18next";
import OnIcon from "../../omnia/components/elements/icon";
import WorkspaceDialog from "./workspace-dialog";
import {useIsMobile} from "../../omnia/hooks/use-is-mobile";
import WorkspaceMenuItem from "./workspace-menu-item";
import {useTheme} from "@mui/system";


function WorkspacePopover({ workspaces, popover, onSelect, onUpdate, onDelete, currentWorkspace }) {

    const dialog = useDialog();
    const {isMobile} = useIsMobile();
    const { t } = useTranslation();
    const theme = useTheme();
    const workspaceCount = workspaces?.length;

    return (
        <>
            <Popover
                anchorEl={popover.anchorRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                onClose={popover.handleClose}
                open={popover.open}
                slotProps={{
                    paper: {
                        sx: {
                            ...{borderRadius: theme?.config?.card_radius + "px"},
                            ...{p: 0},
                            ...{minWidth: 200},
                        },
                    },
                }}
            >
                <Box sx={{minWidth: 230}}>
                    <List
                        sx={{maxHeight: 400, overflowY: 'auto'}}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        mt={2}
                    >
                        {workspaces?.map((workspace) => (
                            <WorkspaceMenuItem
                                key={'workspace-' + workspace.id}
                                workspace={workspace}
                                onSelect={onSelect}
                                onEdit={dialog.handleOpen}
                                onDelete={workspaceCount > 1 ? onDelete : null}
                                checked={currentWorkspace?.id === workspace.id}
                                dense={true}
                            />
                        ))}
                        <MenuItem
                            fullWidth
                            sx={{minHeight: isMobile ? 22 : 'auto', py: 0.5, mt: 2}}
                            onClick={() => dialog.handleOpen(null)}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <OnIcon iconName="Plus" size="small"/>
                                <Typography variant="subtitle1">
                                    {t('common.create_new')}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    </List>
                </Box>
            </Popover>
            <WorkspaceDialog
                open={dialog.open}
                onClose={dialog.handleClose}
                onUpdate={onUpdate}
                workspace={dialog.data}
            />
        </>
    )
}

WorkspacePopover.propTypes = {
    workspaces: PropTypes.object.isRequired,
    popover: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    currentWorkspace: PropTypes.object,
};

export default WorkspacePopover;